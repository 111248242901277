<template>
    <dashboard-layout>
        <v-card class="mx-auto mt-4" max-width="500">
            <v-card-text>
                <v-row class="mb-5">
                    <v-col v-if="activePricePlan && activePricePlan.name">
                        Your Plan is: {{ activePricePlan.name }}
                    </v-col>
                    <v-col class="text-right">
                        <v-btn :to="{name: 'dashboard.billing.select-plan'}">Select Plan</v-btn>
                    </v-col>
                </v-row>
                <stripe-checkout-card
                    :loading="paymentLoading"
                    @submit-start="paymentLoading = true"
                    @submit-card="savePaymentMethod"
                    @submit-error="paymentLoading = false"
                />


                <div class="mt-15">
                    <div v-if="paymentMethods.length === 0" class="">
                        No payment method found, please add a payment method.
                    </div>
                    <div v-else>
                        <v-row v-for="(method, key) in paymentMethods"
                            :key="'method-'+key"
                            @click="paymentMethodSelected = method.id"
                            class="border rounded"
                            v-bind:class="{
                            'bg-success text-light': paymentMethodSelected == method.id
                            }"
                        >
                            <v-col class="col-12 col-sm-2">
                                {{ method.brand.charAt(0).toUpperCase() }}{{ method.brand.slice(1) }}
                            </v-col>
                            <v-col class="col-12 col-sm-7">
                                Ending In: {{ method.last_four }} Exp: {{ method.exp_month }} / {{ method.exp_year }}
                            </v-col>
                            <v-col class="col-12 col-sm-3">
                                <span v-on:click.stop="removePaymentMethod( method.id )">Remove</span>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </v-card-text>

        </v-card>
    </dashboard-layout>
</template>

<script>
import BillingApi from "@/api/BillingApi";
import StripeCheckoutCard from "@/components/StripeCheckoutCard";
import {mapMutations} from "vuex";
import PricePlan from "@/models/PricePlan";
import DashboardLayout from "@/layouts/DashboardLayout";

export default {
    name: "Index",
    components: {DashboardLayout, StripeCheckoutCard},
    data: function () {
        return {
            paymentMethods: [],
            paymentMethodSelected: {},
            activePricePlan: {},
            paymentLoading: false,
        }
    },
    methods: {

        loadPaymentMethods() {
            window.axios.get('/api/billing/payment-methods').then(response => this.paymentMethods = response.data);
        },
        removePaymentMethod(methodStripeId) {
            BillingApi.removePaymentMethod(methodStripeId).then(() => {
                this.loadPaymentMethods()
                this.showSnackBar({color: 'success', timeout: 3000, text: 'Billing Method Removed Successfully'})
            })
        },
        savePaymentMethod(method) {
            window.axios.post('/api/billing/payments', {
                payment_method: method
            }).then(() => {
                this.paymentLoading = false
                this.showSnackBar({color: 'success', timeout: 3000, text: 'Billing Method Added Successfully'})
                this.loadPaymentMethods()
            })
        },
        ...mapMutations(['showSnackBar']),
    },
    async mounted() {
        this.loadPaymentMethods();
        if (this.$auth.user().price_plan_id) {
            this.activePricePlan = await PricePlan.find(this.$auth.user().price_plan_id)
            if(this.activePricePlan.name == 'Artist Pro') this.activePricePlan.name = 'Artist ROCK'
        }
    }
}
</script>

<style scoped>

</style>
